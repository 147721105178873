.one {
  margin: 20px 0;
}

.filter {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-bottom: 20px;
}
.filter button {
  width: 150px;
  font-size: 20px;
  padding: 5px;
  border: 1px solid darkgray;
}

.row.maps {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.maps button {
  width: 200px;
  font-size: 20px;
  padding: 10px 15px;
  color: #fff;
  border: 1px solid #000;
  background-color: #000;
  border-radius: 40px;
  position: relative;
  top: 42px;
  z-index: 9;
}
i.fa-solid.fa-map-location-dot {
  margin-left: 10px;
}
