.ql-container {
  min-height: 200px !important;
}

.boxxxx {
  border: 1px solid red;
}

.buttons2 {
  float: left;
}

.addFaqs {
  position: absolute;
  top: 50px;
  right: 10%;
}
.buttons2 i {
  color: #ff385c !important;
}
.react-responsive-modal-closeButton {
  display: none !important;
}

.dispCentr {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.delModel {
  padding: 30px;
}
