/* .css-1tywgxn-MuiStepLabel-label {
  color: rgba(0, 0, 0, 0.87);
  font-weight: 600 !important;
  font-size: 18px !important;
} */

.profile-box {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  border-radius: 2px;
  padding: 45px;
  margin-top: 25px;
}

.dateSize {
  font-size: 10px;
  font-weight: 600;
}

.save-search .out-pay {
  border: 1px solid #757575;
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
  font-size: 14px;
}

.card.rounded-lg.shadow-sm.border-0.p-2.cursor-pointer:hover {
  box-shadow: 0 0 0.5rem 0.125rem rgba(0, 0, 0, 0.2) !important;
  border-radius: 8px;
}

.col-2.col-md-2.dates {
  font-size: 10px;
}

.card.rounded-lg.shadow-sm.border-0.p-2.cursor-pointer {
  height: 170px;
  margin-bottom: 10px;
}

.smallFile video {
  width: 130px !important;
  height: 100px !important;
}

.delete-bots button {
  width: 20%;
  margin: 20px;
}

.housingButton {
  width: 100% !important;
}

p.col-md-6.col-6.leftw {
  text-align: center;
}

p.col-md-6.col-6.leftww {
  text-align: center;
}

.sale-div {
  text-align: left;
}

.left-kar {
  text-align: left !important;
}

.stx {
  position: relative;
}

.stx i {
  color: goldenrod;
}

.stx span {
  position: absolute;
  right: 10px;
}

.thy .spic {
  float: left;
  margin-right: 4px;
  width: 16px;
  height: 16px;
  margin-top: 3px;
}

.image-box {
  width: 150px;
  height: 150px;
  border: 1px solid black;
  border-style: dashed;
  margin-top: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.files-box {
  width: 400px;
  height: 60px;
  border: 1px solid black;
  border-style: dashed;
  margin-top: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.files-box img {
  height: 30px;
  width: 30px;
}

h6.mt-3.text-dark {
  text-align: left;
}

img.profileImage {
  width: 100%;
  object-fit: contain;
  height: 100%;
}

.profileContainer {
  background-color: rgba(251, 251, 251, 1) !important;
  padding: 55px 100px !important;
  height: 210px;
}

.card-title-eipo {
  font-size: 22px;
  font-weight: 600;
  margin-top: 7px;
}

img.previewImage {
  width: 100px;
  height: 100px;
  margin-left: 9px;
  border-radius: 50%;
  object-fit: cover;
}

.listingContainer {
  padding: 50px 90px;
}

.filterBtn {
  height: 58px;
  margin-bottom: 16px;
}

img.itemImage {
  height: 200px;
  width: 100%;
  object-fit: cover;
}

.tab {
  display: flex;
  justify-content: space-between;
  font-size: 11px;
  font-weight: 600;
}

.tab p {
  margin-bottom: 5px;
}

h6.property-nam {
  font-size: 18px;
}

.semibold {
  font-weight: 600;
}

.head-functions {
  position: absolute;
  width: 90%;
  top: 15px;
  display: flex;
  justify-content: space-between;
}

/* .head-functions .status {
  background-color: #ff385c;
  color: white;
  padding: 6px 28px;
  margin: 10px;
} */
.head-functions .status {
  background-color: #ff385c;
  color: white;
  padding: 5px 12px;
  margin: 10px;
  font-weight: 600;
  font-size: 12px;
  /* position: relative; */
  /* top: -8px; */
}

.head-functions2 .status {
  background-color: #ff385c;
  color: white;
  padding: 6px 28px;
  margin: 10px;
  position: absolute;
  left: 0;
  top: 0;
}

/* .icons > span {
  background-color: white;
  margin: 10px 5px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
  cursor: pointer;
} */

.icons>span {
  background-color: #ff385c;
  margin: 10px 5px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  cursor: pointer;
}

.item-box.border.p-3.position-relative {
  color: #757575;
}

.head-functions2 {
  position: absolute;
  width: 90%;
  top: 18px;
  display: flex;
  justify-content: flex-end;
}

.head-functions2 i {
  font-size: 25px;
  color: red;
}

.fav-lists i {
  font-size: 25px;
  color: red;
}

p.text-sm.asd {
  margin-bottom: 51px;
}

.leftw {
  position: relative;
}

.leftw:before {
  content: '';
  display: block;
  width: 36px;
  height: 1px;
  background: grey;
  left: 87%;
  top: 46%;
  transform: rotate(90deg);
  position: absolute;
}

.greyTxt {
  background: #f2f2f2 0% 0% no-repeat padding-box;
  opacity: 1;
  font-size: 12px !important;
  color: #757575;
  position: absolute;
  left: 12px;
  right: 12px;
  bottom: 0;
}

.greyTxt p {
  margin: 0;
  padding: 10px;
}

.fotoo {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.dlr {
  font-size: 20px !important;
  font-weight: 600;
  color: #000;
}

.spic {
  float: left;
  margin-right: 4px;
  width: 23px;
  height: 23px;
}

.thy span {
  font-size: 12px;
}

.mapChecks {
  border-bottom: 1px solid #ff385c4f;
}

.smallIcon img {
  width: 27px;
  height: 30px;
}

.botm-btn button i {
  padding-right: 5px;
}

.botm-btn button {
  float: left;
  width: 100%;
  border: 1px solid #dbdbdb;
  color: #ff385c;
  padding: 5px;
}

.notification .itemImages {
  max-width: 100%;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  float: left;
}

.notImg i {
  padding-top: 12px;
  padding-left: 10px;
}

.posRel {
  position: relative;
}

.unsaveButton i {
  color: #757575;
}

.unsaveButton:hover i {
  color: #ff385c;
}

.golf {
  position: relative;
}

.unsaveButtonDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.unsaveButton {
  border: 1px solid lightgray;
  height: 40px !important;
  width: 40px !important;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 4px;
  padding: 5px;
}

/* 
.crated-span {
  color: #ff385c !important;
} */
/* new css start */
.fw-400 {
  font-weight: 400 !important;
}

.profile-banner {
  border-radius: 50px;
}

.bg-overlay {
  background: #000000AB;
  border-radius: 50px;
}

.rounded-10 {
  border-radius: 10px;
}

/* .customer-box span {
  font-size: 18px;
} */

.customer-box span img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
}

.shadow2 {
  box-shadow: 1px 0px 7px 1px #FF385C24;
}

/* new css end */
@media (max-width: 1024px) {
  .profileContainer {
    background-color: rgba(251, 251, 251, 1) !important;
    height: 190px;
  }
}

.profileCardImage {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  object-fit: contain;
}

.paymentBooks {
  height: 50px;
  /*  */
}

.setWidth {
  max-width: 31px;
}

.setWidthImg {
  max-width: 61px;
}

.setWidthdot {
  max-width: 11px;
}

.home-prop-col {
  margin-bottom: 20px;
}

.prf-card {
  background: #f9f9f9 !important;
  background: #f9f9f9 !important;
}

.profileContainer {
  background: #f2f2f2 !important;
  background: #f2f2f2 !important;
}

.profile-top {
  gap: 10px;
}

.profileContainer .flex-column {
  justify-content: center;
  height: 100%;
}

.flx-tenants {
  flex-flow: row wrap;
}

@media (max-width: 1199px) {
  .col-md-4.col-4.thy {
    width: 40%;
  }

  .profileContainer {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
}

@media (max-width: 1024px) {
  .greyTxt p {
    padding: 10px 5px;
  }

  .flx-tenants .image-box button {
    padding: 0 !important;
  }
}

@media (max-width: 991px) {
  .home-prop-col button {
    padding: 0 !important;
    margin-bottom: 20px;
  }

  .files-box {
    width: 340px;
  }
}

.profile-box .rdw-editor-wrapper {
  margin: 0;
}

@media (max-width: 767px) {
  .greyTxt {
    position: relative !important;
    left: 0 !important;
    right: 0;
    bottom: -12px;
  }

  .card-title-eipo {
    font-size: 17px;
  }

  .profileCardImage {
    width: 40px;
    height: 40px;
  }

  .notImg i {
    padding-top: 4px;
    padding-left: 6px;
  }

  .notification .itemImages {
    height: 22px;
    width: 22px;
  }

  .crete-business .col-12 {
    padding: 0 !important;
  }

  .cross-pic .image-box button {
    padding: 0 !important;
  }

  .flx-tenants .image-box button {
    padding: 0 !important;
  }

  .profile-col button.btn.ms-3 {
    margin: 0 auto !important;
    width: 100% !important;
  }

  .listing-prf {
    flex-flow: row wrap;
  }

  .profile-top .flex-column {
    margin-left: 0 !important;
  }

  .profileContainer {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .profileContainer .d-flex.justify-content-end.profile-col {
    flex-flow: column;
    gap: 10px;
    margin-top: 10px;
    justify-content: center !important;
  }

  .profile-top {
    flex-flow: column;
    gap: 12px;
  }

  .files-box {
    width: 222px;
    margin-bottom: 30px;
  }

  .profile-box {
    padding: 25px 15px;
  }

  .home-prop-col button {
    margin-bottom: 0px;
  }

  button.housingButton {
    margin: 0;
  }

  .col-12.prop-rent-col {
    padding: 0;
  }

  .prop-rent-col {
    margin-top: 20px;
  }

  .profileContainer .row {
    width: 100%;
    margin: 0 auto;
  }

  .profileContainer {
    height: max-content !important;
  }

  .ms-5.profl-lft {
    margin-left: 15px !important;
    font-size: 14px;
  }

  .home-prop-col {
    padding: 0 !important;
    margin-top: 20px !important;
  }

  .row.apart-row {
    margin: 10px auto;
    gap: 20px;
  }

  p.text-sm.asd {
    margin-bottom: 1px;
  }
}