/* home banner css */
.promo-object-main {
  background-size: contain;
  background-position: right center;
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  background-color: #1f2228;
}

.promo-content span.image-curve {
  position: absolute;
  left: -40px;
  top: -60px;
  width: 103px;
}
span.icon-right {
  position: absolute;
  right: 0;
  bottom: 40px;
}
span.around img {
  margin: auto;
  position: relative;
  bottom: -20px;
  left: -30px;
}
/* logos */
.logo-main .row > div {
  width: 20%;
}

span.star img {
  width: 92px;
}
span.star {
  position: absolute;
  right: -20px;
}

/* about section designing */
.about-right-content h6 span {
  width: 52px;
  height: 1px;
  background-color: #ff7043;
  display: inline-block;
  position: relative;
  top: -6px;
  margin-right: 12px;
}
.about-right-content h6 {
  letter-spacing: 1.35px;
  color: #ff7043;
  text-transform: uppercase;
}

.heading-main h3 {
  letter-spacing: 0px;
  color: #3a3a3a;
  font-weight: 700;
  font-size: 28px;
  line-height: 38px;
}
.about-right-content.heading-main {
  padding-left: 40px;
}
.about-right-content p {
  letter-spacing: 0px;
  color: #313131;
  line-height: 30px;
}

/* nov-08 */
.small-heading {
  font-size: 18px;
  color: #f87147;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.middle-heading {
  font-size: 30px;
  max-width: 48%;
  margin: 0 auto;
  font-weight: 400;
  padding-bottom: 20px;
  letter-spacing: 0.5px;
  padding-top: 10px;
}
.bold {
  font-weight: 700;
}
.digital img {
  width: 130px;
  height: auto;
}

h3.promo-heading {
  font-size: 14px;
  letter-spacing: 3px;
  color: #e86d5a;
}

a.navbar-brand img {
  max-width: 170px;
  height: auto;
  width: auto;
}
.gallery-item {
  margin: 15px 5px;
}
.medium {
  font-weight: 600;
}
.f-18 {
  font-size: 18px;
}

.concept-content img {
  margin: 0 auto 20px auto;
  height: 68px;
}
.f-24 {
  font-size: 24px;
}
.brder::before {
  content: '';
  background: #e86d5a;
  height: 1px;
  width: 58px;
  display: inline-block;
  margin-right: 10px;
  position: relative;
  bottom: 6px;
}
.brder {
  position: relative;
}

.main-tabs .nav-tabs .nav-link {
  color: #000;
}

.main-tabs .nav-tabs .nav-link.active {
  border: none;
  color: #e86d5a;
}
.f-16 {
  font-size: 16px;
}
.f-13 {
  font-size: 13px;
  line-height: 24px;
}
.will-projects .slick-dots {
  bottom: -90px;
}
.will-projects .main-tabs .nav-item button:hover {
  border: 1px solid #fff !important;
}
section.will-projects h2 {
  padding-bottom: 30px;
  padding-top: 20px;
}
.main-tabs ul {
  border: none;
  padding-bottom: 50px;
}
.main-tabs li {
  position: relative;
  padding-right: 20px;
}
.main-tabs li.nav-item::before {
  content: '';
  background: #e26d5c;
  height: 6px;
  width: 6px;
  border-radius: 100%;
  position: absolute;
  top: 17px;
  display: inline-block;
}

.f-40 {
  font-size: 40px;
}
ul.contct-list {
  list-style: none;
  padding: 0;
  margin: 0;
}
ul.contct-list li img {
  height: 50px;
  margin-right: 15px;
}
a {
  text-decoration: none !important;
}

.contact-left {
  background: #fff;
  border-radius: 4px;
  padding: 30px;
  height: 100%;
  margin-right: 30px;
}
ul.contct-list li {
  padding: 8px 0;
}

.f-14 {
  font-size: 14px;
  line-height: 24px;
}
.social-lisitng li i {
  background: #e86d5a;
  color: #fff;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
}
ul.menu-listing,
ul.address-listing {
  padding-left: 0;
}
ul.address-listing li {
  padding-bottom: 10px;
}
.footer-col.left-space {
  padding-left: 15px;
}

.footer-col.right-space {
  padding-right: 15px;
}
ul.address-listing i {
  font-size: 18px;
  margin-right: 10px;
}
ul.menu-listing li {
  padding-bottom: 5px;
}
ul.social-lisitng {
  display: flex;
  padding: 20px 0 0 0;
  margin: 0;
}
.social-lisitng li {
  padding-right: 10px;
}
h3.number-heading {
  color: #ff7043;
  -webkit-text-fill-color: white;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #ff7043;
  font-size: 60px;
  font-weight: 600;
  padding-bottom: 0px;
}
.form-field.btn {
  width: 38% !important;
  padding-top: 0 !important;
}
.newsletter .form-grp {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}
.newsletter .form-grp input[type='submit'] {
  background: #e86d5a;
  color: #fff;
  width: max-content;
  padding: 10px 20px;
}
.newsletter .form-grp .form-field input {
  width: 100%;
  padding: 9px;
  border-radius: 40px;
  font-size: 12px;
}
.newsletter .form-grp .form-field {
  width: 60%;
}
.newsletter .form-grp .form-field input {
  width: 100%;
}
button.bg-black {
  border-color: #000;
}
.upload-content h4 {
  max-width: 100%;
  font-size: 20px;
  font-weight: 600;
  max-width: 100%;
  color: #3a3a3a;
}
.upload-content h4 {
  max-width: 100%;
}
.upload-content {
  text-align: left;
  border: 1px solid #eee;
  padding: 50px 20px;
}
.will-projects .slick-slide img {
  border: 1px solid #ecebeb;
  padding: 10px;
  height: auto;
  width: 95% !important;
}

/* about css */

section.promo-about-pg {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom -86px;
  padding: 140px 0 !important;
}

/* about css ends */

/* media query start */

@media (min-width: 1600px) {
  .f-14 {
    font-size: 17px !important;
    line-height: 30px;
  }
  .newsletter .form-grp .form-field input {
    padding: 14px;
    font-size: 16px;
    height: -webkit-fill-available;
  }
  .social-lisitng li i {
    height: 50px;
    width: 50px;
    font-size: 20px;
  }
  .newsletter .form-grp .form-field {
    padding: 0 !important;
  }
  .upload-content h4 {
    font-size: 25px;
  }
  .digital img {
    width: 162px !important;
  }
  .upload-content p {
    font-size: 18px;
    line-height: 32px;
  }
  section.promo-object-main {
    height: 100vh;
  }
  .about-right-content h6,
  .small-heading,
  .main-tabs .nav-tabs .nav-link {
    font-size: 20px !important;
  }
  .middle-heading {
    font-size: 40px;
  }
  .heading-main h3 {
    font-size: 36px;
    line-height: 44px;
  }
  .f-16,
  ul.contct-list li {
    font-size: 18px !important;
    line-height: 32px !important;
  }
  .promo-content h1 {
    font-size: 72px !important;
  }
  a.navbar-brand img {
    max-width: 230px;
  }
  h3.promo-heading,
  .navigation-main ul li a {
    font-size: 16px !important;
  }
  .f-18 {
    font-size: 21px;
  }
  .f-24 {
    font-size: 28px;
  }
  button.btn {
    font-size: 18px !important;
    padding: 14px 28px !important;
  }
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container:not(.container.header-navs) {
    max-width: 90% !important;
  }
}

@media (min-width: 2700px) {
  a.navbar-brand img {
    max-width: none !important;
    width: 100% !important;
    height: 130px;
}
}
@media (max-width: 1199px) {
  .container {
    max-width: 100% !important;
  }
  .main-tabs li {
    padding-right: 10px !important;
  }
  .promo-content {
    padding-right: 70px !important;
  }
}
@media (max-width: 1024px) {
  .promo-content h1 {
    font-size: 52px !important;
  }
  .main-tabs .nav-tabs .nav-link {
    font-size: 15px;
  }
}
@media (max-width: 991px) {
  .button-dash-content.d-flex.align-items-center {
    width: 100%;
    justify-content: flex-end;
  }
  .upload-content {
    padding: 50px 20px 20px 20px;
    min-height: 370px;
  }
  .navigation-main .container {
    flex-direction: column;
    width: 100%;
    position: relative;
  }
  .footer-title h4 {
    font-size: 16px;
    padding-bottom: 12px !important;
  }
  .address-listing li a {
    display: flex;
    align-items: center;
  }
  .promo-content h1 {
    font-size: 30px !important;
  }
  .navigation-main .container button.navbar-toggler {
    position: absolute;
    right: 20px;
    top: 13px;
  }
  footer {
    padding: 60px 15px !important;
  }
  .form-field.btn {
    width: max-content !important;
    padding: 0 !important;
  }
  .footer-col.left-space {
    padding-left: 0 !important;
  }
}

@media (max-width: 767px) {
  .navigation-main button.btn.bg-orange span {
    font-size: 14px;
    font-weight: 600;
    padding: 0px 0px;
    margin: 0px !important;
  }

  .navigation-main button.btn {
    margin-right: 2px !important;
    font-size: 14px !important;
  }
  .main-tabs .nav-tabs .nav-link {
    font-size: 14px;
  }
  .main-tabs li {
    padding-right: 02px !important;
  }
  .navigation-main button.btn,
  button.bg-orange {
    font-size: 14px !important;
    padding: 8px 15px !important;
  }
  .promo-content h1 {
    font-size: 40px !important;
  }
  .button-dash-content {
    padding-top: 10px;
  }
  .about-right-content.heading-main {
    padding-left: 015px;
  }

  .middle-heading {
    font-size: 26px;
    max-width: 100% !important;
  }
  .footer-col.right-space {
    padding: 40px 0 020px 0 !important;
  }

  .footer-col.left-space {
    padding-left: 0;
  }
  footer {
    padding: 60px 15px 30px 15px !important;
  }
  .will-projects .slick-slide img {
    width: 100% !important;
  }
  .bottom-content {
    flex-flow: column;
  }
  button.bg-orange span {
    font-size: 14px;
    font-weight: 600;
    padding: 6px 20px;
    margin: 0px !important;
  }
  span.star {
    right: 20px;
  }
  span.star img {
    width: 92px;
  }
  span.icon-right,
  .promo-content span.image-curve {
    display: none;
  }
  span.around img {
    width: 63px;
  }
  .logo-main .row > div {
    width: 33.3%;
    padding: 10px;
    text-align: center;
  }
  .about-right-content {
    padding: 30px 15px;
  }
  .promo-object-main::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgb(0 0 0 / 75%);
  }
  .promo-object-main {
    height: auto;
    background-size: cover;
    position: relative;
  }
  .column {
    margin: 10px !important;
  }
  .about-right-content p {
    font-size: 16px;
    line-height: 25px;
  }
  .heading-main h3 {
    font-size: 30px;
    line-height: 38px;
    margin-bottom: 0px;
  }
}

@media (max-width: 575px) {
  .logo-main .row > div {
    width: 50%;
  }
  .logo-main .row > div img {
    margin: auto;
  }
}
.mapChecksState {
  background-color: #fff;
  padding: 3px 0 0 10px;
  border-bottom: 1px solid lightgray;
}
