header.site-header {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    z-index: 9;
}

.new-search {
    padding: 0px 0 0px;
}

.search-content-bg.rounded {
    padding-top: 110px;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
}

.top-searching-tabs ul {
    flex-wrap: unset;
    overflow-x: auto;
    justify-content: start !important;
    padding: 10px 0px 10px 25px;
}

.top-searching-tabs ul li {
    white-space: nowrap;
}

.top-searching-tabs ul::-webkit-scrollbar {
    display: none;
}

iframe {
    display: none !important;
}

.top-searching-tabs {
    border-radius: 52px !important;
    background: #FFFFFF45;
    padding: 25px !important;
}

.inner-width-wrapper {
    background: #111111;
    border-radius: 40px;
    overflow: hidden;
    padding-top: 30px;
    padding-bottom: 40px;
}

.top-searching-tabs p.heading-wrapper {
    border-bottom: 0.6px solid #303030;
    margin: 0;
    padding-bottom: 18px;
    margin: 0 25px;
    margin-bottom: 15px;
    font-size: 20px;
    font-weight: 500;
    line-height: 25px;
    text-align: left;
}

.top-searching-tabs .search-tabs-form {
    background: transparent;
    border-radius: 10px;
    padding: 25px;
}

.top-searching-tabs .search-tabs-form .container {
    padding: 0;
}

.top-searching-tabs button.nav-link {
    border: 0.6px solid #CCC4C4;
    border-radius: 12px;
    background: #222222;
    box-shadow: 0px 8px 12px 0px #0000000D;
    color: #fff;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
}

.top-searching-tabs button.nav-link.active {
    background: #FF385C;
    border: 0.6px solid #CCC4C4;
}

button.wlcm-text {
    border: 0.6px solid #CCC4C4;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 400;
    line-height: 1;
    padding: 15px 18px;
}

span.ticker-label {
    background: #FF385C;
    width: 100px;
    height: 10px;
    display: block;
    margin-bottom: 15px;
}

.content-wrapper a.link {
    background: #FF385C;
    border-radius: 10px;
    color: #fff;
    width: -moz-fit-content !important;
    width: fit-content !important;
    padding: 18px 30px;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 500;
    line-height: 1;
}

.top-searching-tabs .search-tabs-form .loc-select>div>div:nth-child(2) {
    background: transparent;
}

.top-searching-tabs .search-tabs-form .loc-select>div * {
    border: 0;
}

.top-searching-tabs .search-tabs-form .loc-select>div {
    background: #222222 !important;
    border: 0.6px solid #CCC4C4;
    border-radius: 10px;
}

.select-tag-wrapper select {
    width: 100%;
    background: transparent;
}

.select-tag-wrapper:not(.button-tag-wrapper) {
    border-radius: 10px;
    background: #222222;
    border: 0.6px solid #CCC4C4;
    min-height: 60px;
    display: flex;
    /* align-items: center; */
    margin-bottom: 15px;
    /* padding: 10px 40px; */
}

.select-tag-wrapper span.selectIcon {
    left: 10px;
    max-width: 18px;
    top: 50%;
    transform: translateY(-50%);
}

.select-tag-wrapper span.chevDown {
    right: 15px;
    max-width: 12px;
    top: 50%;
    transform: translateY(-50%);
}

.select-tag-wrapper select {
    width: 100%;
    background: transparent;
    appearance: none;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    padding: 0 40px;
}

.select-tag-wrapper select:focus-visible {
    outline: 0;
}

.select-tag-wrapper.button-tag-wrapper button {
    min-height: 60px;
    border-radius: 10px;
    text-align: center;
    width: 100%;
    background: #FF385C;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
}

header.site-header .button-dash-content i {
    color: #fff !important;
}

.logo-list-wrapper .logo-slide {
    width: fit-content;
    white-space: nowrap;
    text-align: center;
    flex: 0 0 auto;
}

.logo-list-wrapper .logo-slide .cat-filter-categories button {
    border: 0.6px solid #CCC4C4 !important;
    display: flex;
    width: 100%;
    padding: 8px 15px !important;
    align-items: center;
    gap: 5px;
    border-radius: 12px;
    box-shadow: 0px 8px 12px 0px #0000000D;
}

.logo-list-wrapper .logo-slide .cat-filter-categories button h6 {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: #252525;
    margin-bottom: 0;
}

.logo-list-wrapper {
    overflow-x: scroll;
    gap: 15px;
}

.logo-list-wrapper::-webkit-scrollbar {
    display: none;
}

.logo-list-wrapper .cat-filter-categories.active {
    border-bottom: 0;
}

.logo-list-wrapper .logo-slide .cat-filter-categories button img {
    width: 22px;
    height: 22px;
    object-fit: contain !important;
    filter: unset;
}

.heading-wrapper-section p.heading {
    font-size: 32px;
    font-weight: 500;
    line-height: 36.72px;
    color: #1e1e1e;
    margin: 0;
}

.search-block {
    border: 0.6px solid #CCC4C4;
    border-radius: 14px;
    max-width: 400px;
    min-height: 55px;
    width: 350px;
}

.search-block input {
    width: 100%;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    text-align: left;
    color: #616161;
    padding: 0 10px 0 40px;
}

.search-block {
    display: flex;
    overflow: hidden;
}

.search-block span.searchIcon {
    max-width: 20px;
    top: 50%;
    transform: translateY(-50%);
    left: 15px;
}

.heading-wrapper-section {
    margin-bottom: 2rem;
}

.residential-tab-cards .row {
    gap: 35.225px;
}

.residential-tab-cards .col-md-3 {
    width: 23%;
}

.residential-tab-cards .resi-card-box .resi-popup {
    /* display: none !important; */
}

.residential-tab-cards .resi-card-box .slick-dots {
    display: none !important;
}

.residential-tab-cards .res-slider-icon img {
    background: linear-gradient(0deg, #D9D9D9, #D9D9D9),
        linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
    border-radius: 30px;
}

.residential-tab-cards .price {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    text-align: left;
    color: #2B2B2B;
}

.residential-tab-cards .title {
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #2B2B2B;
}

.residential-tab-cards .location {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #808080;
}

.residential-tab-cards .wish-popup span.bg-pop {
    background: transparent;
}

.residential-tab-cards .wish-popup span.bg-pop i {
    font-size: 22px;
}

.resi-popup span.bg-pop {
    background: transparent;
    position: static;
}

.residential-tab-cards .resi-card-box .resi-popup {
    /* display: none !important; */
    height: auto;
    position: absolute;
    top: 14px;
    right: 14px;
    height: 30px;
    width: 30px;
    background: transparent;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.scroll-bottom-btn {
    max-width: 350px;
    /* background: #FFFFFF; */
}

.scroll-bottom-btn img {
    max-width: 30px;
}

.scroll-bottom-btn button {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #616161;
    position: relative;
    z-index: 2;
}

.select-tag-wrapper select option {
    color: #000;
}

.select-tag-wrapper input {
    background: transparent;
}

.select-tag-wrapper.range-tag-wrapper {
    padding: 0 15px;
    align-items: center;
    gap: 15px;
}

.select-tag-wrapper.range-tag-wrapper span.text {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    display: block;
    width: auto;
    white-space: nowrap;
}

.residential-tab-cards .resi-card-box .resi-propname {
    background: #ffffff;
    color: #ff385c;
    font-size: 0.7rem !important;
    padding: 2px 12px;
    border-radius: 30px;
    position: absolute;
    bottom: 20px;
    left: 16px;
    font-weight: 600;
}

.residential-tab-cards .resi-card-box .resi-propname span {
    position: static;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
}

.scroll-bottom-btn img.bg-img {
    max-width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    object-fit: fill;
    z-index: 1;
}


@media (min-width: 1600px) {

    .container-xxl,
    .container-xl,
    .container-lg,
    .container-md,
    .container-sm,
    .container {
        max-width: 90% !important;
    }
}