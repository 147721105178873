.property-name {
  color: #000;
  font-size: 40px;
  font-weight: 600;
  margin-top: -15px;
  position: relative;
}
.short-Title {
  font-size: 20px;
  padding-left: 10px;
  color: #afafaf;
  position: absolute;
  right: 9px;
  top: 14px;
}
.inquireformhover {
  position: relative;
}
.message {
  position: absolute;
  top: -70px;
  left: 10px;
  background-color: #000;
  color: #fff;
  border-radius: 3px;
  padding: 5px 10px;
  display: none;
}
.inquireformhover:hover .message {
  display: block !important;
}

.titleHeaders {
  color: #ff385c;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  width: 100%;
  margin: 0;
  padding: 0;
  display: block;
  height: 40px;
}
.property-category {
  font-size: 15px;
  color: #626262;
  margin-top: -12px;
  margin-bottom: 25px;
}

.property-description {
  font-size: 20px;
}

.aps {
  align-items: flex-start;
  text-align: left !important;
}
.propert-details .cardss.iqforms {
  padding: 30px;
  border-radius: 10px;
  background: #fff !important;
}
.left-columns {
  float: left;
  padding-left: 44px;
  padding-top: 30px;
}

.hider {
  display: none !important;
}

.col-md-6.col-12.left-columns {
  float: left;
  padding-left: 35px;
}

.miniBox .mainBOx .inBOxc {
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.25);
  margin: 1px;
  text-align: center;
  display: flex;
  align-items: center;
  padding: 15px 0px 0px 0px;
  border-radius: 5px;
  justify-content: center;
  color: #000;
}
.rent-slide .owl-nav {
  display: none;
}
.main-invest-return {
  padding: 20px 25px;
  border-radius: 8px;
  border: 1px solid #ff385c30;
}
.main-invest-return .rsmain .rsInner .toprs {
  font-size: 15px;
  margin: 0;
  line-height: 20px;
  min-height: 45px;
}
.main-invest-return .rsmain .rsInner .bottomrs {
  font-weight: 600;
  margin: 0;
}
.main-invest-return .rsmain .rsInner {
  margin: 10px 0;
  height: auto;
}
.rsmain.invest-return .rsInner {
  border-radius: 10px;
}

.miniBox .mainBOx .inBOxc {
  color: #000;
}
.cardss.iqforms {
  padding: 61px;
  width: 100%;
}
img.staticImg {
  width: 90px;
  float: left;
  margin: 0px 21px 20px 12px;
}
.miniBox .mainBOx .inBOxc i {
  font-size: 25px;
  color: #afafaf;
}

.miniBox .mainBOx .inBOxc .rightSide {
  font-size: 30px;
  color: #000;
  font-weight: 600;
}

.address {
  padding-top: 40px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.leftSide span {
  font-size: 26px;
  font-weight: 500;
  margin-left: 10px;
}
.address .tds {
  font-size: 22px;
}

.address img {
  width: 50px;
  height: 50px;
}

.imageNames {
  position: absolute;
  border: 1px solid #ff385c;
  padding: 4px 15px;
  color: #fff;
  top: 10px;
  left: 10px;
  border-radius: 5px;
  font-weight: 500;
  font-size: 12px;
  background-color: #ff385c;
}
.picsfor img {
  border-radius: 10px;
  width: 100%;
  height: 148px;
  object-fit: cover;
}
.main-datas {
  display: flex;

  padding: 20px;
}

.main-datas .left-div {
  padding: 30px;
}

.main-datas .right-div {
  padding: 0 80px 0 0;
  /* font-size: 25px; */
  text-align: center;
  align-items: center;
}

.cardss {
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.25);
}
.cardss:hover {
  box-shadow: 0 3px 15px 0 rgba(255, 0, 0, 0.45);
  border-radius: 13px;
}

.main-datas2 {
  display: flex;

  padding: 20px;
}

.main-datas2 .right-div {
  padding: 30px;
}

.main-datas2 .left-div {
  padding: 0 0 0 80px;
  font-size: 25px;
  text-align: center;
  align-items: center;
}

.row.main-datas.col-6.col-md-6 {
  padding: 33px;
}

.row.main-datas2.col-6.col-md-6 {
  padding: 33px;
}
.place-offers-rw .titleHeader {
  border-bottom: 1px solid #dcdcdc;
  padding-bottom: 10px;
  color: #000;
}

.titleHeader {
  color: #ff385c;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  width: 100%;
  margin: 10px 10px 30px 0px;
  display: block;
}
.titleHeader2 {
  color: #434343;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  width: 100%;
  margin: 10px 10px 30px 10px;
  display: block;
  text-align: left;
}
.rsmain .rsInner {
  margin: 20px 0;
  height: 90px;
  padding: 10px;
  background-color: #fff;
  text-align: center;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.25);
}

.rsmain .rsInner:hover {
  box-shadow: 0 3px 15px 0 rgba(255, 0, 0, 0.45);
  border-radius: 13px;
}
.rsmain .rsInner .toprs {
  color: #afafaf;
  font-size: 18px;
  font-weight: 600;
}

.rsmain .rsInner .bottomrs {
  color: #ff385c;
  font-size: 18px;
}
.clearBack {
  background-color: transparent !important;
  color: #ff385c !important;
  width: 100% !important;
  margin: 10px 0;
}
.pinkBack {
  width: 100% !important;
  background-color: #ff385c !important;
  color: #fff !important;
  margin: 10px 0;
}
.hhh {
  color: #ff385c !important;
}
.propertyCarousel {
  float: left;
  height: auto;
  width: 100%;
  background-color: #fff;
  margin: 20px 0;
  /* box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.25); */
  border-radius: 10px;
}

.propertyCarousel .owl-carousel.owl-loaded {
  float: left;
}
.owl-item p.pp3 {
  font-size: 12px;
}
.owl-item p.pp2 {
  font-weight: 600;
  font-size: 15px;
}
.noter.prop-cont {
  padding-top: 50px;
}
.propertyCarousel .item img {
  padding: 0px;
  border-radius: 5px;
  height: 400px;
  object-fit: cover;
}
.propertyCarousel .owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  width: 36px;
  display: flex;
  height: 36px;
  align-items: center;
  justify-content: center;
  color: #000;
  display: flex;
  display: inline;
  margin: 5px;
  border-radius: 50%;
  filter: Alpha(Opacity=50);
  font-size: 23px;
}
.noter .owl-nav button.owl-prev {
  background: rgb(255, 180, 180) !important;
}
.noter .owl-nav button.owl-next {
  background: rgb(255, 180, 180) !important;
}
.propertyCarousel button.owl-prev {
  position: absolute;
  left: 3px;
  top: 190px;
  visibility: hidden;
}

.propertyCarousel button.owl-next {
  position: absolute;
  right: 3px;
  top: 190px;
  visibility: hidden;
}

.propertyCarousel:hover button.owl-prev {
  visibility: visible !important;
}

.propertyCarousel:hover button.owl-next {
  visibility: visible !important;
}

.modalSection .react-responsive-modal-modal {
  width: 30% !important;
  text-align: left !important;
}
.main-datas.propert-details .inquiry-form-ad {
  margin-left: 40px;
}

/* new csss */
.inquiry-form-ad .titleHeader2 {
  color: #ff385c;
  margin: 0 0 15px 0;
}
.place-offers-maincol p {
  padding: 8px 10px;
  margin: 0;
  display: flex;
  align-items: center;
}
.inquiry-form-ad.addscroll {
  position: fixed;
  top: 10px;
}
button.dark-btn {
  background: #ff385c !important;
  border-color: #ff385c !important;
}
.place-offers-maincol .right-div.aps {
  padding: 0;
}
.place-offers-maincol {
  padding: 0 20px 20px 20px;
}
.adrs.house-pr-add {
  display: flex;
  flex-flow: row wrap;
}
.place-offers-rw .cardss {
  border-radius: 10px;
}
.place-offers-rw .right-div.aps {
  padding: 0;
}
.adrs.house-pr-add p {
  width: 48%;
  font-size: 16px;
  margin-bottom: 10px;
  display: inline-flex;
  align-items: center;
}
.adrs.house-pr-add i,
.place-offers-maincol i {
  background: #ffffff;
  height: 35px;
  width: 35px;
  border-radius: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #ff385c;
  font-size: 18px;
  box-shadow: 1px 3px 8px #ced4da;
  margin-right: 10px !important;
}
/* @media (max-width: 1024px) {
  .owl-carousel .owl-item img {
    display: block;
    width: 20%;
  }

  button.owl-next {
    right: 0;
  }

  button.owl-prev {
    left: 0;
  }
} */
