.outer {
  float: left;
  width: 100%;
  padding: 25px 30px 25px 30px;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 90px;
  position: relative;
  top: -48px;
  box-shadow: 0px 3px 20px #00000029;
}

.saveButton {
  border: 1px solid lightgray;
  padding: 5px;
  border-radius: 10px;
  width: 40px;
  height: 40px;
  margin-left: 8px;
  margin-top: 3px;
}

.saveButton i {
  color: #ff385c;
}

.saveButton:hover {
  border: 1px solid #ff385c;
  background-color: #ff385c;
}

.saveButton:hover i {
  color: #fff;
}

.roundbox {
  float: left;
  width: 100%;
  padding: 0 80px;
}

.outer .left {
  float: left;
}

.outer .left .bloks {
  float: left;
}

.outer .right {
  float: left;
  text-align: center;
  display: flex;
  justify-content: right;
}

.prices {
  color: #333;
  background-color: #ddd;
  border-color: #ddd;
  font-weight: 400;
  text-align: left;
  width: 100% !important;
  cursor: pointer;
  font-size: 18px !important;
  padding: 10px 18px !important;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  border: 1px solid #d5d5d5;
  border-radius: 30px !important;
  opacity: 1;
  font-size: 15px !important;
}

.returns {
  width: 100%;
  position: relative;
  left: 0;
  color: #333;
  background-color: #ddd;
  border-color: #ddd;
  font-weight: 400;
  text-align: left;
  cursor: pointer;
  font-size: 15px !important;
  padding: 10px 28px !important;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  border: 1px solid #d5d5d5;
  border-radius: 30px !important;
  opacity: 1;
}

button.btn.d-flex.justify-content-center.align-items-center.btn-primary.text-white.btn-rounded.bttn {
  padding: 12px 25px !important;
  width: max-content;
  height: max-content;
  display: block !important;
}

.progress-div p {
  width: 100%;
  margin: 0;
  padding: 0;
}

.progress-div {
  position: relative;
  border-radius: 20px;
  box-shadow: 0px 3px 20px #00000029;
  width: 100%;
  top: 15px;
  padding: 10px;
}

.qleft {
  width: 250px !important;
  left: -116px !important;
}

.qright {
  left: 116px !important;
}

.progress-div .p2 {
  padding: 30px 10px 0 10px;
}

.progress-div .p3 .lt {
  text-align: right;
}

@media (max-width: 1024px) {
  .roundbox {
    float: left;
    width: 100%;
    padding: 30px;
    margin-bottom: 10px;
  }
  .searchBar .outer.row {
    align-items: center;
  }
  .outer .left .bloks {
    font-size: 14px;
  }
  .dd .drops {
    margin-bottom: 10px;
  }
  .prices {
    margin-bottom: 10px;
  }
  .jojoDiv {
    width: 300px !important;
    padding: 40px 40px 10px 40px;
    margin-top: 10px;
  }

  .returns {
    left: 0;
  }

  .progress-div {
    margin-bottom: 50px;
  }

  .qleft {
    width: 250px !important;
    left: 0 !important;
    margin-bottom: 50px;
  }

  .qright {
    left: 0 !important;
  }
}

.searchBar .css-yk16xz-control {
  padding: 9px;
}

.searchBar .css-yk16xz-control:hover {
  border: 1px solid lightgray !important;
  border-color: lightgray !important ;
}
.searchBar .css-1pahdxg-control:hover {
  border: 1px solid lightgray !important;
  border-color: lightgray !important ;
}
.searchBar .css-1pahdxg-control {
  border: 1px solid lightgray !important;
  border-color: lightgray !important ;
  border-radius: 100px !important;
  padding: 7px !important;
}
.searchBar .css-1f43avz-a11yText-A11yText {
  border: 1px solid lightgray !important;
  border-color: lightgray !important ;
}
.searchBar .css-26l3qy-menu {
  border-radius: 5px !important;
}
