li.notification-item.unread {
  background-color: #ff581f2e;
}

li.notification-item.unread:hover {
  background-color: #ff581f5e;
}
.leftMr {
  padding-left: 10px;
}
li.notification-item.unread .light {
  color: #00000082;
}
.nonotifications {
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-icon.dropdown-toggle::after {
  content: none !important;
}

.user-icon {
  font-size: 20px;
}

ul.dropdown-menu.notify-drop.show {
  left: -500px !important;
}

button#dropdownMenuButton1 {
  position: relative;
  left: 11px;
}
.notification-head {
  padding: 10px;
}

button.d-flex.sds.justify-content-center.align-items-center.text-black.py-2.pe-3.text-md.br-50.user-icon.dropdown-toggle {
  position: relative;
  left: 14px;
}

.dash-men .dropdown {
  background-color: transparent;
}

.count-notification {
  font-size: 11px;
  font-weight: 500;
  color: #fff;
  border: 1px solid #ff385c;
  border-radius: 50%;
  width: 18px;
  background-color: #ff385c;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px;
  position: absolute;
  top: -4px;
  left: 8px;
}

.notify-navigate-button {
  width: 100%;
}

@media (max-width: 767px) {
  .login-vendor ul.dropdown-menu.notify-drop.show {
    width: 300px !important;
    left: -145px !important;
  }
  .row.hr-notify {
    align-items: center;
  }
  .notification-box .lin div {
    padding-bottom: 10px;
    padding-left: 0;
  }
}
