.notification-box {
  border: 1px solid transparent;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.main-notify {
  padding: 30px 0;
}
.cntnt {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.setWidth {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.notification-box .toper button {
  border: 1px solid #ff385c;
  color: #ff385c;
  border-radius: 5px;
  padding: 5px 15px;
  margin-right: 10px;
}

.notification-box .toper button:hover {
  background-color: rgb(245, 221, 224);
}

.col-8.col-md-8.toper {
  display: flex;
  justify-content: flex-end;
}

.notification-box .noti {
  font-size: 25px;
  padding-top: 10px;
  font-weight: 600;
  color: #000;
}

.notification-box .lin {
  border-bottom: 1px solid #eee;
}

.notification-box .lin div {
  padding-bottom: 10px;
}

.notify-navigate-button {
  width: 100%;
  margin-bottom: 6px;
}

.notifyBottom {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.notifyBottom div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
.notification-box .lin div.toper {
  text-align: right;
}
@media (max-width: 767px) {
  .notification-box .toper button {
    margin-bottom: 10px;
  }
  .notification-box .lin div {
    padding-left: 0 !important;
  }
}
