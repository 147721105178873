@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.f-28 {
  font-size: 28px;
}
.f-26 {
  font-size: 26px;
}
.f-30 {
  font-size: 30px !important;
}
.f-14 {
  font-size: 14px;
}
.f-13 {
  font-size: 13px;
}
.f-15 {
  font-size: 15px;
}
.f-50 {
  font-size: 50px;
}
.fw-500 {
  font-weight: 500;
}
.space-top {
  padding-top: 40px;
}
.semi-bold {
  font-weight: 600;
}
.border-tp {
  border-top: 1px solid #ddd;
}
.flex-flow-wrap {
  flex-flow: row wrap;
}
.space-tb {
  padding: 40px;
}

.relative {
  position: relative;
}
.bold {
  font-weight: 700;
}
img.propImages {
  width: 80px !important;
  margin-bottom: 20px;
  height: 80px !important;
}
.holaj {
  font-size: 45px;
  position: relative;
  top: 12px;
  z-index: 999;
  right: -14px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.fslightbox-svg-path {
  background-color: red !important;
}
.rdw-editor-wrapper {
  border: 1px solid lightgray;
  margin: 0 0 0 20px;
}
i.fa-regular.fa-circle-left.go-back-button {
  font-size: 23px;
  padding-left: 20px;
  padding-top: 15px;
  color: #ff86989c;
}

.pdfDiv .previewImage {
  width: 300px !important;
  height: 200px !important;
}

.makeCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
.divCentr {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.makeLeft {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.makeRight {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.f-26 {
  font-size: 26px !important;
}
.bg-light-gray {
  background: #f2f2f2;
}
.dark-color {
  color: #ff385c !important;
}

.bg-dark-pink {
  background-color: #ff385c !important;
}
.bg-pink {
  background-color: #ffebef;
  padding: 15px;
  border-radius: 6px;
  height: fit-content;
}

.form-control.phone-control {
  height: 58px;
}
.iqforms .phone-control input {
  padding-top: 7px;
}
input:focus {
  outline: none !important;
  box-shadow: none !important;
}
.width-full {
  width: 100% !important;
}
.company-categories .nav button {
  background: #ffe7eb;
  /* border: 1px solid #ff385c59 !important; */
  cursor: pointer;
  z-index: 1;
  color: #757575;
  margin-bottom: 6px;
  font-size: 14px;
}
.roundbox .MuiInputBase-formControl {
  border-radius: 54px !important;
}

.roundbox .priceButton {
  border-radius: 54px !important;
}

.roundbox .css-yk16xz-control {
  border-radius: 61px !important;
}

i {
  color: #ff385c !important;
}

.css-xjv0pg-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
.css-xjv0pg-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
  color: #ff385c !important;
}
.fa-trash {
  color: #fff !important;
}
.fa-pen {
  color: #fff !important;
}
.saveButton:hover i {
  color: #fff !important;
}
.alignmentLogo {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}
.fa-plus {
  color: #fff !important;
}
.right-footer i {
  color: #fff !important;
}
.fa-square-xmark {
  color: black !important;
}
/* .dash-men i {
  color: black !important;
} */
.fa-heart {
  color: red !important;
}
.whiteColor {
  color: #fff !important;
}
.fa-trash-can {
  color: #fff !important;
}

.rightAlignButton {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-end !important;
}

.leftAlignButton {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
}

.centerAlignButton {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
i.fa-solid.fa-circle.notificationDot {
  font-size: 8px;
}

.PhoneInputCountry {
  width: 10% !important;
  float: left;
  padding: 11px 0;
}
input.PhoneInputInput {
  margin: 0 !important;
  padding: 8px;
  width: 86% !important;
  font-size: 18px;
}
.managementAbout {
  border: 1px solid gray;
  margin-top: 30px;
  padding: 15px;
}
.managementAbout img.showImage {
  width: 125px;
  height: 125px;
  border: 1px solid #c1c1c1;
  border-radius: 50%;
}
.rights {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.lefts {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

@media (max-width: 991px) {
  .RRT__accordion .RRT__panel {
    margin-bottom: 20px;
  }
  .crosses-select .image-box button {
    padding: 0 !important;
  }
}
@media (max-width: 767px) {
  input.PhoneInputInput {
    font-size: 14px;
    width: 76% !important;
  }
  .meeting-booking {
    margin: 15px 0 !important;
  }
  .meeting-sent .row {
    padding: 0;
  }
  .meeting-btns {
    text-align: center;
    gap: 10px;
    margin-bottom: 20px !important;
  }
  .meeting-btns button {
    margin: 0 auto;
  }
  .unsaveButtonDiv.paymentBooks {
    justify-content: flex-end;
  }
  .ity p {
    padding: 0 !important;
  }
  .later-col {
    flex-flow: column;
    gap: 10px;
  }
  .later-col button {
    width: 100% !important;
  }
  .notification.unread .col-12 {
    padding: 10px !important;
  }
  .formDiv {
    display: block !important;
    padding: 0 !important;
  }
  button.notify-navigate-button {
    padding: 0 !important;
  }
  .row.ity {
    padding-bottom: 10px !important;
  }
  .col-md-6.col-sm-6.out-pay {
    padding: 20px 0 !important;
  }
  .row.mydeals-rw .col-12,
  .savedItem,
  .company-details .col-12 {
    padding: 0 !important;
  }
  .cross-pic {
    padding-left: 0 !important;
  }
  .rdw-editor-wrapper {
    margin: 0;
  }
  .listingContainer > .d-flex {
    flex-flow: row wrap;
  }
  .profile-box .col-12.mt-4 {
    margin-top: 10px !important;
  }
  .listing-today {
    text-align: center;
  }
  .listing-today h6 {
    font-size: 14px;
  }
  i.fa-regular.fa-circle-left.go-back-button {
    padding-left: 5px;
  }
  .PhoneInputCountry {
    width: 20% !important;
  }
  .login-acc {
    padding: 20px 5px !important;
  }
}
.mobile-center {
  text-align: center;
}
.mobile-center.font {
  font-size: 15px;
  width: 100%;
}
.logStyle {
  font-weight: 600;
  letter-spacing: 1px;
  color: #c6c6c6;
  text-align: center;
}
.fav-banner {
  margin-top: 60px;
}
/* --------------- Draft Js Design ------------------ */

.public-DraftStyleDefault-ltr {
  direction: ltr;
  text-align: left;
  padding: 0 10px;
}
.rdw-editor-toolbar {
  border: 1px solid #ff385c !important;
  background: #ff385c !important;
}
/* .rdw-editor-toolbar {
  border: 1px solid #fbccd3 !important;
  background: #fbccd3 !important;
} */

.rdw-option-wrapper {
  border: 1px solid #ff385c !important;
  padding: 10px !important;
}
.rdw-option-wrapper {
  border: 1px solid #ff385c !important;
  padding: 6px !important;
  width: 26px !important;
  height: 26px !important;
  border-radius: 7px !important;
}
.draftHead {
  /* color: #ff385c; */
  color: #000;
  font-weight: 500;
  margin-bottom: -2px;
}
.rdw-option-disabled {
  opacity: 1 !important;
}
.profile-contain {
  margin-top: 60px;
}
.container-fluid.profileContainer > div {
  justify-content: center;
  height: 100%;
}
.mobile-inquiry {
  display: none;
}
.about-privacy .abt {
  padding: 30px;
  border-bottom: 5px solid #ff385c !important;
  border: 1px solid #ffcfd7;
}

/* responsive css */
@media (max-width: 767px) {
  .mobile-inquiry {
    display: block !important;
  }
  .about-privacy .about-us-box {
    padding: 20px 12px;
    font-size: 14px;
  }
  .desktop-inquiry {
    display: none;
  }
}
@media (max-width: 600px) {
  .rdw-option-wrapper {
    min-width: 18px !important;
    padding: 4px !important;
    margin: 0 1px !important;
    width: 22px !important;
    height: 22px !important;
  }
  .no-space {
    padding: 0 !important;
  }
  .about-tenant {
    padding: 20px 5px !important;
  }
  .oulets.main-from .property-details-tabs .company-categories .row > div {
    padding: 0 !important;
  }
  .invest-listing .f-15 {
    font-size: 13px;
  }
  .invest-listing {
    padding-bottom: 6px;
  }
  .oulets.main-from .property-details-tabs {
    padding: 0;
  }
  .oulets.main-from .property-details-tabs .nav.nav-pills {
    margin-right: 0 !important;
  }
  .creatie-project .profile-box {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }
  .rdw-option-wrapper img {
    height: 11px;
  }
}
select.customSelectForm {
  background-color: #fff;
  border: 1px solid #d5d3d3;
  width: 100%;
  padding: 18px 10px;
}
.howItTabs ul li {
  list-style: disc !important;
  margin-bottom: 20px !important;
}
.googleEarth {
  font-size: 50px !important;
}
.howItWOrk {
  margin-right: 10px;
  font-size: 16px !important;
  cursor: pointer !important;
  padding: 8px !important;
}
.proCounting {
  font-size: 14px;
}
.buildChoti {
  object-fit: contain !important;
  width: 90px !important;
  height: 90px !important;
}
