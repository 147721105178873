.social-accounts div {
  border: 1px solid #d5d5d5;
  padding: 15px;
  margin: 10px 0px;
  border-radius: 12px;
  cursor: pointer;
}

.social-accounts div:hover {
  scale: 1.02;
  transition: all 0.2s ease-in-out;
}

.register-form input[type='date']::-webkit-calendar-picker-indicator {
  position: absolute !important;
  right: 10px;
  top: 19px;
}

@media (max-width: 767px) {
  .social-accounts div {
    padding: 8px;
    margin: 10px 0px;
  }
  .row.auth-lyot-rw {
    flex-flow: column-reverse;
    padding-bottom: 40px;
  }
  html body .login-acc {
    padding: 20px 5px !important;
  }
  .text-bubbled {
    font-size: 13px;
    text-align: center;
  }
  .social-accounts button {
    font-size: 13px;
    padding-right: 0 !important;
    padding-left: 4px !important;
  }
}
