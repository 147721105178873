.ql-container {
  min-height: 200px !important;
}

.boxxxx {
  border: 1px solid red;
}
.deleteTrash {
  color: #ff385c !important;
}
.buttons2 {
  float: left;
}

.addFaqs {
  position: absolute;
  top: 50px;
  right: 10%;
}
