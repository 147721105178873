.property-projects h1 {
    font-size: 32px;
}
span.counter {
    border: 1px solid #263238;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    flex: 0 0 40px;
    position: relative;
    z-index: 2;
    background: #fff;
}
li.sidebar-item:after {
    content: '';
    height: 100%;
    width: 1px;
    display: block;
    background: #000;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 20px;
    z-index: 1;
}
li.sidebar-item:first-child::after {
    top: 50%;
}
li.sidebar-item:last-child::after {
    bottom: 50%;
    top: unset;
}
.sidebar-wrapper ul {
    padding: 0;
    margin: 0;
}
.content span {
    color: #263238;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.78px;
}
.sidebar-wrapper ul .content {
    text-align: right;
}
.sidebar-wrapper ul .content p.title {
    font-size: 15px;
    font-weight: 600;
    line-height: 17px;
    color: #1E1E1E;
    margin-bottom: 5px;
}
.sidebar-wrapper ul .content p.subtitle {
    font-size: 13px;
    font-weight: 500;
    line-height: 16.44px;
    text-align: right;
    margin: 0;
}
.nextPrevArrows span.arrow {
    width: 40px;
    height: 40px;
    display: block;
    border-radius: 50%;
    background: #2632380D;
    display: flex;
    align-items: center;
    justify-content: center;
}
.nextPrevArrows span.arrow img {
    max-width: 14px;
}
li.sidebar-item.active span.counter {
    background: #FF385C;
    border-color: #FF385C;
    color: #ffff;
}
.tab-buttons button.tab-button {
    font-size: 15px;
    font-weight: 600;
    line-height: 17px;
    color: #263238;
    padding: 12px 12px;
    border-bottom: 1px solid #000;
}
.tab-buttons button.tab-button.active {
    background: #F4F5F5;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom-color: #FF385C;
    color: #FF385C;
}
button.add-btn {
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    color: #FF385C;
}
.property-info-form p.form-heading {
    border-bottom: 1px solid #0000001A;
}
.form-block .form-floating {
    display: flex;
    flex-direction: column-reverse;
}
.form-block .form-floating label {
    position: static;
    transform: unset !important;
    font-size: 15px;
    line-height: 17.78px;
    color: #263238 !important;
    padding: 0 0 10px;
    opacity: 1 !important;
}
.form-block .form-floating select.form-select {
    border: 1px solid #26323899;
    border-radius: 10px;
    min-height: 46px;
    padding-top: 0;
    padding-bottom: 0;
    max-height: 46px;
}
.form-block .form-floating textarea.form-control {
    border: 1px solid #26323899;
    border-radius: 10px;
    min-height: 46px;
    padding-top: 0;
    padding-bottom: 0;
}
.form-block .mb-3 {
    margin-bottom: 5px !important;
}
.form-block .mb-3:not(:first-child) {
    margin-top: 20px !important;
}
.form-block span.requiredField {
    font-size: 14px;
    font-weight: 400;
    line-height: 16.44px;
    text-align: left;
    color: #263238;
    opacity: 0.7;
}
.form-block .form-floating input[type="file"] {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
}
.form-block .form-floating.upload-files {
    max-width: 150px;
}
.next-prev-buttons .arrow {
    min-width: 40px;
}
.next-prev-buttons .arrow img {
    max-width: 16px;
}
.next-prev-buttons button.arrow {
    background: #FF385C;
    border: 1px solid #FF385C;
    color: #fff;
    padding: 10px 15px;
    border-radius: 50px;
    font-size: 14px;
}
.next-prev-buttons button.arrow img {
    filter: invert(1);
}
.form-block .form-floating input[type="number"] {
    border: 1px solid #26323899;
    border-radius: 10px;
    min-height: 46px;
    padding-top: 0;
    padding-bottom: 0;
    max-height: 46px;
}
.form-block input.form-control {
    border: 1px solid #26323899;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    height: 45px !important;
    border-radius: 10px;
}
.form-heading img {
    max-width: 30px;
}
span.editIcons {
    width: 15px;
    cursor: pointer;
    height: 15px;
}
span.editIcons img {
    height: 100%;
    object-fit: contain;
}
.text-editor {
    border: 1px solid #D0D0D0;
    border-radius: 14px;
    overflow: hidden;
    background: #fff;
}
.text-editor .editIcons-main {
    background: #F4F5F5;
    padding: 16px 20px;
    border-bottom: 1px solid #D0D0D0;
}
.form-component .card-body {
    background: #FAFAFA;
}
.text-editor textarea.form-control {
    border: 0 !important;
}
.text-editor textarea.form-control:focus-visible {
    outline: 0;
}
.text-editor textarea.form-control:focus {
    box-shadow: none;
}
.form-block .text-editor .position-relative.mb-3 {
    margin-top: 0 !important;
    min-height: 250px;
}
.requiredIcons .form-floating label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 10px;
}
img.reqIcon {
    max-width: 14px;
}
.row.col-gap {
    column-gap: 40px;
}
.row.col-gap .col-md-6 {
    width: calc(50% - 20px);
}
.form-component .card {
    overflow: hidden;
}

@media (min-width: 1600px) {
    .container-xxl,
    .container-xl,
    .container-lg,
    .container-md,
    .container-sm,
    .container {
        max-width: 90% !important;
    }
}

@media (max-width: 1199px) {
    .container {
      max-width: 100% !important;
    }
}