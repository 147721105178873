@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

$primary: #ff385c;
$secondary: #434343;
$min-contrast-ratio: 2.5;
$btn-font-size: 13px;
$btn-border-radius: 1px;
$btn-border-radius-sm: 1px;
$font-family-sans-serif: 'Poppins';
$btn-line-height: 18px;
$card-cap-bg: white;
$card-cap-padding-y: 15px;
$input-border-radius: 2px;
$list-group-item-padding-y: 3px;
$body-color: #757575;
$light-color: #c6c6c6;

.btn-block {
  min-width: 245px;
}

.text-bubbled {
  font-weight: 600;
  letter-spacing: 1px;
  color: $light-color;
}

.text-facebook {
  color: #2d5c95;
  font-size: 24px;
}

.form-control:focus {
  border-left: 5px solid $primary !important;
}

.form-control {
  color: #000 !important;
}
.semi-bold {
  font-weight: 600;
}
.Accords .accordion-header button {
  margin-bottom: 0;
  background: #ffe7eb !important;
  margin-bottom: 8px;
}
.Accords .accordion-item {
  background: transparent !important;
  border: none !important;
}
h3,
h4 {
  color: $secondary;
  font-weight: 600 !important;
}
.underlined-title {
  border-bottom: 2px solid #ff385c;
  width: max-content;
}
.f-26 {
  font-size: 26px;
}
h1 {
  color: $secondary;
  font-weight: 700 !important;
}

.btn-heavy {
  font-weight: 600 !important;
}

.btn-rounded {
  border-radius: 30px !important;
}

.form-floating {
  color: #8a8c8f;
}

.dialog-width {
  min-width: 600px;
}

.width-600 {
  width: 600px;
}

.text-muted {
  font-weight: 300;
}

.text-bold {
  font-weight: 500;
}

.w-85 {
  width: 85% !important;
}

.f-34 {
  font-size: 34px;
}

.f-20 {
  font-size: 20px;
}

.f-15 {
  font-size: 15px;
}

.light {
  color: #b3b8bd;
}

.bold {
  font-weight: 800;
}

.dark {
  color: #1a1818;
}

.orange {
  color: #ef6d58 !important;
}

.f-14 {
  font-size: 14px;
}

.w-35 {
  width: 35% !important;
}

.bg-button {
  background-color: rgb(231 233 238) !important;
  border: none !important;
}

table .dropdown-menu.show {
  top: 0 !important;
  overflow: auto;
}

.py-25 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.text-sm {
  font-size: 13px;
  letter-spacing: 1px;
}

.dropdown-menu {
  top: 110% !important;
  left: -133px !important;
  transform: translate(15px, 2px) !important;
  z-index: 2;
}

.passwordIcon {
  position: absolute;
  right: 13px;
  top: 18px;
  cursor: pointer;
}

.w-45 {
  width: 45% !important;
}

.w-55 {
  width: 55% !important;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-position: right calc(0.375em + 1.8rem) center !important;
}

@media (max-width: 1024px) {
  form .row.registerone {
    margin: 30px 10px !important;
  }
}

@media (max-width: 767px) {
  .register-step-card {
    width: 100% !important;
  }

  .register-step-card .row {
    margin: 010px 0 !important;
  }

  .register-step-card .card-header {
    padding: 15px 10px !important;
  }
}

@media (min-width: 640px) {
  .size-medium .sm\:max-w-lg {
    max-width: 40rem;
  }

  .size-large .sm\:max-w-lg {
    max-width: 60rem;
  }
}

span.icon {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid grey;
  color: grey;
  font-weight: 300;
  border-radius: 28%;
  padding: 14px;
}

span.icon:hover {
  transform: scale(1.1);
  border: 1px solid black;
  color: black;
}

.col-55 {
  flex: 0 0 auto;
  width: 45% !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.small-drawer {
  max-width: 20rem;
}

.digits input {
  text-align: center;
}

.digits input:focus {
  border-left: unset !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.boxes > .card {
  margin-bottom: 27px;
  height: 150px;
  cursor: pointer;
}

.boxes img {
  width: 50px;
}

img.showImage {
  width: 100%;
  max-height: 200px;
  object-fit: contain;
}

@media (max-width: 1199px) {
  html body .w-35 {
    width: 50% !important;
  }
}

@media (max-width: 992px) {
  html body .w-35 {
    width: 62% !important;
  }
}

@media (max-width: 767px) {
  html body .w-35,
  html body .w-50,
  html body .w-75 {
    width: 96% !important;
  }
  .dialog-width {
    min-width: 0 !important;
  }
}

@import '~bootstrap/scss/bootstrap.scss';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
