.rentTop {
  display: flex;
  align-items: center;
  justify-content: center;
}
.forR {
  background-color: #ff385c;
  padding: 10px 20px;
  border-radius: 35px;
  color: #fff;
}

.propTitle {
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 40px;
}
.rentTop .money {
  color: #ff385c;
  margin-top: 30px;
}
.descri {
  padding-top: 30px;
}

.descri h6 {
  font-size: 30px;
  font-weight: 600;
  color: #000;
}
.descri span {
  font-size: 14px;
  margin-right: 30px;
}

.place-offers-maincol span {
  margin-top: 21px;
  width: 22% !important;
  display: flex;
  float: left;
  align-items: center;
}
.amenitiesDiv {
  float: left;
  width: 100%;
  margin-bottom: 20px;
}
.descri hr {
  float: left;
  width: 100%;
}
.lowProfile .propertyCarousel {
  margin-top: 100px !important;
}
.lowProfile .propertyCarousel .item img {
  padding: 0px;
  border-radius: 5px;
  height: 400px;
  object-fit: cover;
}
textarea.form-control.h-100 {
  height: 100px !important;
}
.place-offers-maincol.rent-view {
  display: flex;
  flex-flow: row wrap;
}
.facilities.rent-faclity {
  display: flex;
  align-items: baseline;
  gap: 25px;
}
@media (max-width: 1199px) {
  .descri span {
    font-size: 14px;
    width: 45%;
  }
  .adrs.house-pr-add {
    display: flex;
    gap: 10px;
    flex-flow: row wrap;
  }
}

@media (max-width: 767px) {
  .propTitle,
  .descri h6 {
    font-size: 20px;
  }
  .place-offers-maincol span {
    margin-top: 16px;
    width: 100% !important;
  }
  .descri span {
    width: 100%;
  }
}
