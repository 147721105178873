.br-50 {
  border-radius: 50px !important;
}

.MuiBox-root.css-1wnsr1i {
  border-radius: 14px !important;
  border: none !important;
}

.modTitleSpan {
  font-weight: 600 !important;
  font-size: 18px !important;
  position: relative;
  top: -6px;
  color: #000;
}

.mkdCr button {
  width: 70px;
  margin-left: 10px !important;
}

.bg-grey {
  background-color: #f9f9f9;
}

.mkdCr {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.navigation-main button.btn span,
button.bg-orange span {
  font-weight: 600;
}

.fool button.btn {
  padding: 15px 20px !important;
}

nav.navbar.navigation-main {
  padding: 12px 0;
}

.navigation-main ul li a {
  letter-spacing: 0px;
  color: #1a1818 !important;
  text-transform: uppercase;
  opacity: 1;
  font-size: 13px;
  background-color: #f8f9fa;
  line-height: 24px;
  font-weight: 500;
  padding-left: 18px !important;
  padding-right: 18px !important;
}

span.active {
  width: 12px;
  height: 12px;
  background-color: green;
  position: absolute;
  right: -2px;
  bottom: 4px;
  border-radius: 50px;
}

.dah-image {
  position: relative;
}

.dah-image img {
  width: 45px;
  height: 45px;
  border-radius: 50px;
  object-fit: cover;
}

.promo-object-main span.promo {
  color: #ef6d58;
  font-size: 22px;
  font-weight: 300;
  font-family: 'Sofia Pro';
}

.dash-name h4 {
  font-size: 14px;
  line-height: 16px;
  font-weight: 800;
  margin: 0px;
}

.dash-name p {
  font-size: 13px;
  letter-spacing: 0px;
  color: #94a3af;

  margin: 0px;
}

.dash-name {
  text-align: right;
}

.promo-content h1 {
  color: #fff;
  font-family: 'Sofia Pro';
  font-size: 56px !important;
}

button.btn {
  font-size: 15px;
  padding: 10px 20px !important;
  border-radius: 4px;
}

.promo-content P {
  letter-spacing: 0px;
  color: #ffffffa3;
}

header ul li a.nav-link.active::after {
  content: '';
  background: #f65f14;
  height: 4px;
  width: 100%;
  display: block;
  position: absolute;
  bottom: -21px;
  margin: 0 auto;
  left: 0;
  right: 0;
}

header ul li a.nav-link {
  position: relative;
}

.notification-drop {
  margin-right: 28px;
}

.notification-drop a.dropdown-item {
  background: transparent !important;
}

.notification-drop a.dropdown-item {
  cursor: pointer;
}

.notification-drop .dropdown-toggle::after {
  display: none;
}

.notification-drop button {
  background: #f2f4f6 !important;
  border: none !important;
  border-radius: 100% !important;
  height: 48px;
  width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000 !important;
  font-size: 20px;
}

.notification-drop button::before {
  display: none !important;
}

.dropbottom ul.dropdown-menu.show {
  width: 400px;
}

.dashbrd-mnu a.dropdown-item {
  color: #9f9f9f;
  font-size: 14px;
}

.dashbrd-mnu a.dropdown-item i {
  margin-right: 10px;
}

.dashbrd-mnu li:last-child a.dropdown-item {
  color: #f65f14 !important;
}

.dashbrd-mnu li:last-child a.dropdown-item:active {
  color: #fff !important;
}

.dashbrd-mnu {
  padding: 6px !important;
}

.note-list {
  margin: 0;
  align-items: center;
}

.note-list span {
  line-height: normal;
  font-weight: 500;
}

.note-list span.light {
  margin-left: 8px;
}

.dashboard-heaer {
  padding-right: 32px;
}

.note-list img {
  margin-right: 15px;
  height: 52px;
}

.dropbottom ul.dropdown-menu li:last-child {
  border: none !important;
}

.dashbrd-mnu a.dropdown-item:active {
  color: #fff !important;
}

.customer-addrss {
  background: #f8ece5;
  margin-bottom: 10px;
}

.login-vendor li a i {
  color: #ff385c;
  font-size: 14px;
  width: 24px;
}

.login-vendor ul li a:hover {
  background: #ff385c !important;
  color: #fff !important;
}

.login-vendor ul li a:hover i {
  color: #fff !important;
}

.login-vendor ul li {
  padding: 1px 6px;
}

.login-vendor .dropdown-menu {
  border: 1px solid #ff385c45;
}

.login-vendor ul a.dropdown-item {
  background: #fff;
  margin: 3px 0;
  border-radius: 30px;
}

.notification-drop a.dropdown-item:active {
  color: #000 !important;
}

.notification-drop a.dropdown-item.orange:active {
  color: #ff385c !important;
}

.sub-menu ul {
  margin: 0;
  padding: 0;
}

.sub-menu button {
  width: 100%;
  letter-spacing: 0px;
  letter-spacing: 1px;
  color: #555555;
  font-size: 13px;
  font-weight: 400;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 14px;
  margin-bottom: 5px;
}

.navbar-toggler-icon {
  background-image: none !important;
}

.sub-menu button:hover {
  background-color: #f5f3f3;
}

.border-btm {
  border-bottom: 1px solid #ddd;
}

/* responsive */

@media (max-width: 1199px) {
  .navigation-main ul li a {
    font-size: 12px;
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
}

@media (max-width: 1024px) {
  button.btn {
    font-size: 14px;
  }

  .navigation-main ul li a {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .dash-men {
    align-items: center;
  }
}

@media (max-width: 991px) {
  header nav .header-navs {
    flex-flow: row !important;
    align-items: center !important;
  }

  .navigation-main button.btn,
  button {
    padding: 8px 16px !important;
  }

  header .navbar-collapse.collapse.show {
    position: absolute;
    width: 100%;
    left: 0;
    top: 84px;
    background: #ff385c !important;
    z-index: 99;
  }

  header ul li a.nav-link {
    background: #ff385c;
    color: #fff !important;
    position: relative;
  }

  header nav .button-dash-content {
    justify-content: flex-end !important;
    padding-right: 80px;
  }

  .navigation-main .container button.navbar-toggler {
    top: 5px !important;
  }
}

@media (max-width: 767px) {
  header nav .header-navs {
    flex-flow: row wrap !important;
  }

  .navigation-main button.btn span {
    font-size: 0;
    margin: 0 !important;
  }

  .fool button.btn {
    padding: 12px 14px !important;
  }

  header nav .button-dash-content {
    padding-right: 0 !important;
  }
}

.notification-content {
  max-width: 80%;
}

.notification-content img {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid transparent;
}

li.notification-item {
  cursor: pointer;
  padding: 15px;
  border-bottom: 1px solid #eee;
}

li.notification-item:hover {
  background-color: #f5f3f3;
}

.count {
  position: absolute;
  top: -6px;
  right: -5px;
  background-color: red;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 10px;
  font-weight: 800;
}

.AgentHeader {
  margin-bottom: 10px;
  width: 100%;
  padding-top: 9px;
  background-color: #f7e9eb;
  padding: 4px 0 4px 0;
  z-index: 999999999;
}

.AgentHeader div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.AgentHeader p {
  margin: 0;
  padding: 0;
}

.AgentHeader button {
  color: #ff385c;
}

.AgentHeader button:hover {
  color: #ff385c;
  font-weight: 600;
}

.htopNames {
  color: #000;
  /* font-weight: 500; */
}

/* ------------------------ */
.currencyDrop {
  padding: 0 !important;
  position: relative !important;
  margin: 0;
  width: 41px !important;
  font-weight: 600 !important;
  margin-right: 4px;
  background: none !important;
  font-size: 12px !important;
  height: 25px;
  padding-left: 3px !important;
  background: #000 !important;
  border: 1px solid #fff !important;
  color: #fff !important;
}

.currencyDrop::after {
  content: '\2022';
  /* Unicode character for a bullet point, you can replace this with your preferred content */
  margin-left: 10px;
  /* Adjust the spacing as needed */
}

.country-drop {
  border: 1px solid #fff;
}

.lang-flags {
  width: 40% !important;
  margin: 0 !important;
  padding: 0 !important;
  border: 1px solid #fff;
  margin-top: 7px !important;
  cursor: pointer;
}

.hellohaer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.hellohaer .col-md-1 {
  margin: 0 !important;
  padding: 0 !important;
}

/* new header css start */
.menu-nav ul.navbar-nav li a.nav-link {
  background: unset;
  color: #fff !important;
}

.menu-bg {
  border-radius: 0px 0px 0px 70px !important;
  background: #FFFFFF45;
  padding: 0 0 20px 20px;
}

.menu-nav {
  background: #000;
  padding: 30px 100px 30px 30px;
  border-radius: 0 0px 0 50px;
}

a.navbar-brand.logo {
  padding-left: 112px;
}