.search-content-bg {
  background-size: cover;
  background-position: top;
  padding: 40px 60px;
}

.new-search {
  padding: 30px 0;
}

.searching-in {
  background: #000000ad;
  padding: 20px;
  border-radius: 10px;
}

.searching-in button.search-tab {
  background: transparent;
  border: none !important;
  padding: 4px 15px !important;
}

.search-form .search-icon {
  width: 80%;
}

.search-form .search-icon input {
  background-color: #fff;
  border-radius: 8px;
  width: 100%;
  font-weight: 600;
}

.search-form .search-icon i {
  position: absolute;
  right: 18px;
  top: 18px;
  font-size: 18px;
}

.searching-in button.search-btn {
  padding: 17px 30px !important;
  font-size: 18px;
  border-radius: 8px;
}

/* .landing-container {
  max-width: 95%;
  margin: 0 auto;
} */
img.aprt-icon {
  height: 30px;
  filter: grayscale(0.6) invert(0.4);
  margin: 0 auto;
}

.res-slider-icon img {
  height: 265px;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.resi-popup span.bg-pop {
  position: absolute;
  top: 14px;
  right: 14px;
  height: 30px;
  width: 30px;
  background: #fff;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cat-filter-categories:hover .aprt-icon {
  filter: none !important;
}

.cat-filter-categories:hover h6 {
  color: #000 !important;
}

img.share-img-exp {
  height: 75px;
  width: auto;
}

.experience-shares {
  flex-flow: row wrap;
}

.experience-shares button.exp-button {
  width: 46%;
}

.experience-shares button.exp-button {
  width: 46%;
  border: 1px solid #cfcfcf;
  background: transparent;
  color: #000;
  text-align: left;
}

.share-exp-modal {
  padding: 22px;
}

.wish-popup span.bg-pop {
  position: absolute;
  top: 14px;
  right: 54px;
  height: 30px;
  width: 30px;
  background: #000000;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.resi-card-box .wish-popup button.wish-btn.bg-transparent.p-0,
.resi-card-box .resi-popup button.pop-btn.bg-transparent.p-0 {
  padding: 0 !important;
}

.wish-popup {
  height: 0;
}

.cat-filter-categories h6 {
  color: #666;
}

.cat-filter-categories button.cat-link.p-0 {
  padding: 0 !important;
}

.cat-filter-categories.active {
  border-bottom: 2px solid #ff385c;
}

.searching-in button.search-tab.active {
  border-bottom: 2px solid #ff385c !important;
  border-radius: 0;
}

.resi-propname span {
  background: #ffffff;
  color: #ff385c;
  font-size: 0.7rem !important;
  padding: 2px 12px;
  border-radius: 30px;
  position: absolute;
  top: 10px;
  left: 10px;
  font-weight: 600;
}

.main-searching-tabs button.slick-arrow {
  background: gray;
  border-radius: 100%;
  padding: 0;
  height: 21px;
  width: 22px;
  text-align: center;
}

.top-searching-tabs button.nav-link {
  background: #fff;
  color: #000;
  font-size: 14px;
  border-radius: 8px 8px 0 0;
  padding: 14px 15px;
}

.top-searching-tabs button.nav-link.active {
  background: #1a1818;
  color: #fff;
  border-color: #000;
}

.search-tabs-btn button {
  padding: 19px 30px !important;
  border-radius: 8px;
}

.search-prc-range button {
  background: #fff !important;
  padding: 19px 11px !important;
  border-radius: 8px;
  text-align: left;
  justify-content: flex-start !important;
}

.search-prc-range button:hover {
  background-color: #fff !important;
}

.jojoDiv.search-r {
  padding: 15px !important;
}

.loc-input>div,
.loc-select>div {
  margin-bottom: 0 !important;
}

.search-tabs-form input,
.search-tabs-form select {
  border-radius: 8px !important;
}

.search-tabs-form {
  background: #1a1818;
  border-radius: 10px;
  padding: 30px;
}

.loc-select select.form-select {
  height: 99%;
}

.search-tabs-btn button.refresh-button {
  padding: 5px !important;
}

/* .resi-card-box button.slick-arrow.slick-prev::before {
  content: "←";
  background: #dc3545;
  border-radius: 100%;
}

.resi-card-box button.slick-arrow.slick-next::before {
  content: "→";
  background: #dc3545;
  border-radius: 100%;
}

.resi-card-box button.slick-arrow.slick-prev {
  left: -10px;
  z-index: 9999;
}

.resi-card-box button.slick-arrow.slick-next {
  right: -10px;
  z-index: 9999;
} */

/* newlanding css start */
.bg-im-dark {
  background: #FF385C;
}

.heading-color {
  color: #252525;
}

.fs-14 {
  font-size: 14px;
}

.fw-600 {
  font-weight: 600;
}

.fw-500 {
  font-weight: 500;
}

.fw-400 {
  font-weight: 400;
}

.search-content-bg span.ticker-label {
  height: 5px;
}

.search-content-bg .content-wrapper button.wlcm-text {
  background: #00000082;
}

.new-search .search-content-bg {
  padding-top: 170px !important;
}

.residential-tab-cards .row {
  gap: 30px !important;
}

.logo-list-wrapper {
  gap: 10px !important;
}

.new-search .top-searching-tabs button.nav-link {
  border: unset;
}

.new-search .selectBlocks .select-tag-wrapper {
  border: unset;
}

.new-search .selectBlocks button.searchbtn {
  border-radius: 50px !important;
  min-height: 50px !important;
}

.new-search .select-tag-wrapper input[type='range'] {
  background: #ff385c;
  -webkit-appearance: none;
  width: 100%;
  accent-color: #fff;
  height: 6px;
}

.search-content-bg .scroll-bottom-btn {
  display: none;
}

.search-content-bg .content-wrapper a.link {
  border-radius: 30px;
}

.banner-shape {
  background-size: cover;
  background-position: top;
  padding: 30px;
}

.g-bg {
  background: #111111;
  padding: 10px 20px;
  border-radius: 30px;
}

.a-view-btn button,
.a-explore-btn button {
  background: #000;
  color: #fff;
  border-radius: 30px;
  padding: 10px 30px !important;
}

.k-user {
  background: #C6D9E1;
}

.k2-user {
  background: #F4F4F4;
}

.rounded-24 {
  border-radius: 24px;
}

/* pro-project css start */
.pro-project-sec {
  background-size: cover;
  padding: 50px;
}

.prop-text {
  background: #00000099;
  border-radius: 7px;
  padding: 20px;
  position: absolute;
  bottom: 25px;
  left: 25px;
  width: 82%;
}

.prop-img {
  position: relative;
}

.prop-img img {
  border-radius: 12px;
  height: 300px;
  object-fit: cover;
}
/* 
.prop-slider .carousel .carousel-control-prev,
.carousel .carousel-control-next {
  position: unset;
  display: inline-flex;
  background: #000;
  border-radius: 30px;
  width: 40px;
  height: 40px;
}

.prop-slider .carousel .carousel-control-prev-icon,
.carousel .carousel-control-next-icon {
  width: 20px;
} */

/* services-sec start */
.number-box-sec .n-one {
  background: #F1C2B0;
  border-radius: 16px;
}

.number-box-sec .n-two {
  background: #F9EB98;
  border-radius: 16px;
}

.number-box-sec .n-three {
  background: #C0D9DD;
  border-radius: 16px;
}

.number-box-sec .n-four {
  background: #6975E8;
  border-radius: 16px;
}

.number-box-sec .n-five {
  background: #62DBE2;
  border-radius: 16px;
}

.number-box-sec h2 {
  color: #fff;
}

/* need-sec-start */
.can-call-btn .cont-btn .ca-btn button {
  background: unset;
  border: 1px solid #181A20;
  color: #181A20;
  border-radius: 12px;
  padding: 10px 30px !important;
}

.can-call-btn .cont-btn .nu-btn button {
  border-radius: 12px !important;
}

.ca-btn button i {
  rotate: 45deg;
}

/* how-it-works start */
.anderson img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 50%;
}

.rounded-12 {
  border-radius: 12px;
}

.o-img img {
  width: 60px;
  height: 60px;
  object-fit: contain;
}

.how-it-works-sec {
  background: #ff385c24;
}

.o-box {
  position: relative;
  border: 1px solid #0000001c;
  border-radius: 16px;
  background: #fff;
}

span.n1 {
  background: #FF385C;
  font-size: 24px;
  color: #fff;
  border-radius: 50%;
  width: 50px;
  display: flex;
  height: 50px;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  position: absolute;
  top: 20px;
  left: 20px;
  box-shadow: inset -5px -5px 10px rgb(233 227 227 / 50%);
}


/* why-choose-us start */
.why-choose-us .m-location img {
  height: 370px;
  width: 100%;
  border-radius: 50px;
  object-fit: cover;
}

.lady-img img {
  width: 300px;
  height: 300px;
  object-fit: contain;
}

.w-call-img {
  position: relative;
}

.angle-img {
  position: absolute;
  right: 0;
  top: 0;
  width: 270px;
}

.search-container:has(+ .d-block) input {
  border-radius: 14px 14px 0px 0px !important;
}

.search-container:has(+ .d-block.d-none) input {
  border-radius: 14px !important;
}