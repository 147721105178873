.sidebar-width {
  width: 215px;
}

.two-buttons {
  width: 70% !important;
}

.sidebar-width nav a:hover,
.sidebar-width nav a.active {
  background: #606060 !important;
  color: #fff !important;
}

.sidebar-width nav a {
  color: #000 !important;
  font-size: 13px !important;
  padding: 5px 15px !important;
}

div#orders-collapse {
  margin: 10px 0;
}

ul.btn-toggle-nav {
  list-style: circle;
  background: #f9f9f9;
  padding: 10px;
  border-radius: 10px;
  padding-left: 40px !important;
  position: relative;
}

div#orders-collapse a:hover {
  background: transparent !important;
}

div#orders-collapse a {
  padding: 0 !important;
  color: #b7b7b7 !important;
  font-size: 14px !important;
}

div#orders-collapse ul li:hover::marker {
  color: #ff385c !important;
}

div#orders-collapse ul li:hover {
  list-style: disc !important;
}

div#orders-collapse ul li:hover a {
  color: #000 !important;
}

div#orders-collapse ul li {
  padding: 4px 0px;
}

.sidebar-width nav ul li ul li a:hover,
.sidebar-width nav ul li ul li a.active {
  background: transparent !important;
  color: #ff385c !important;
}

.sidebar-over::-webkit-scrollbar {
  width: 0 !important;
}

.sidebar-width ul li ul li a {
  padding: 0 !important;
}

.sidebar-width ul li ul li a {
  padding: 0 !important;
  font-size: 14px !important;
  color: gray !important;
}

.sidebar-width ul li ul li {
  padding: 3px 0;
}

.sidebar-width ul li ul li:hover {
  list-style: disc !important;
}

.sidebar-width ul li ul li:hover::marker {
  color: #ff385c !important;
}

.breadcrumb-box {
  height: 400px;
  background-position: center;
  background-repeat: no-repeat;
}

.bigb {
  height: 500px !important;
}

.overlay-box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(0 0 0 / 48%);
}

.breadcrumb-content {
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 50%;
  text-align: center;
}

.breadcrumb-content h3,
.breadcrumb-content h1,
.breadcrumb-content p {
  color: white;
}

.breadcrumb-content p {
  font-weight: 200;
}

.overlay-box2 {
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 48%);
}

.breadcrumb-box2 {
  height: 400px;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 100px;
}

.col-12.col-md-7.right {
  padding-top: 6%;
}

.breadcrumb-content2 h3 {
  font-size: 50px;
  margin-bottom: 40px;
}

.breadcrumb-content2 h3,
.breadcrumb-content2 h1,
.breadcrumb-content2 p {
  color: white;
}

.breadcrumb-content2 p {
  font-weight: 200;
}

.breadcrumb-content2 .left {
  display: flex;
  align-items: center;
  justify-content: center;
}

span.sideSq {
  width: 70%;
}

span.sideSq img {
  position: relative;
  top: 100px;
  min-height: 280px;
  max-height: 300px;
}

button.bigBtn {
  position: relative;
  top: 115px;
  font-size: 25px !important;
  padding: 30px 110px !important;
  border-radius: 120px !important;
}
.model-datas .left {
  display: flex;
  align-items: end;
  justify-content: end;
}

/* .fool .col-12.col-md-6 {
  display: flex;
  align-items: center;
  justify-content: center;
} */

.sliderSubTxt {
  font-size: 12px;
}

.MenuItemSearch button {
  width: 100%;
  border: 1px solid lightgray;
  background-color: transparent;
  color: #585151 !important;
  padding: 18px !important;
  /* border-radius: 42px; */
}
.MenuItemSearch button:hover {
  /* border: 1px solid lightgray !important; */
  background-color: transparent !important;
  color: #585151 !important;
}
/* -------------------------- Toggle in breadcrum------------------------------------ */
.tog {
  border: 1px solid #fff;
  border-radius: 56px;
  background-color: #fff;
  padding: 5px;
  margin: 0 0 30px 0;
  width: 70%;
}

.inactiveTog {
  color: #757575;
  border: 1px solid #fff;
  margin: 0;
  border-radius: 100px;
  padding: 10px;
  background: #fff;
  transition: all 0.6s ease-in-out;
}

.activeTog {
  color: #fff;
  border: 1px solid #ff385c;
  margin: 0;
  padding: 10px;
  border-radius: 100px;
  background: #ff385c;
  transition: all 0.6s ease-in-out;
}

@media (max-width: 1024px) {
  .breadcrumb-content2 .lefts {
    margin-bottom: 30px;
  }
  .fool .col-12.lefts {
    margin: 0;
  }
  .breadcrumb-content2 h3 {
    font-size: 40px;
    margin-bottom: 40px;
  }

  .breadcrumb-box2 {
    margin-bottom: 68px;
  }

  button.bigBtn {
    position: relative;
    top: 50px;
    font-size: 16px !important;
    padding: 10px 36px !important;
    border-radius: 120px !important;
  }

  .breadcrumb-content {
    width: 63%;
  }

  .tog {
    width: 100%;
  }

  .inactiveTog {
    font-size: 9px;
  }

  .activeTog {
    font-size: 9px;
  }
}

@media (max-width: 767px) {
  .overlay-box .breadcrumb-content {
    width: 85% !important;
  }
}
