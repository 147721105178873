.notifies ul {
  margin: 0;
  padding: 0;
}

.hr-notify div {
  align-items: center;
}

.nonotifications {
  display: flex;
  align-items: center;
  justify-content: center;
}

.notifies ul li:hover .notification {
  background-color: lightgray;
  cursor: pointer;
}

.notifies ul li {
  border-bottom: 1px solid #eee;
}

.row.notification {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 7px 0;
}

.row.notification p {
  margin: 0;
  padding: 0;
}

.dates {
  align-items: flex-end;
}

ul.dropdown-menu.notify-drop.show {
  width: 580px;
  left: -500px !important;
}

.unread {
  background-color: rgb(253, 237, 239);
}

.read {
  background-color: #fff;
}

button.d-flex.justify-content-center.align-items-center.text-black.py-2.pe-3.text-md.br-50.user-icon {
  position: relative;
  left: 25px;
}

button.d-flex.justify-content-center.align-items-center.text-black.px-3.text-md.br-50.user-icon {
  position: relative;
  left: 10px;
  top: 7px;
}

.di {
  display: none;
}

@media (max-width: 1024px) {
  button.d-flex.justify-content-center.align-items-center.text-black.px-3.text-md.br-50.user-icon {
    position: relative;
    left: 10px;
    top: -1px;
  }
  button#dropdownMenuButton1 {
    position: relative;
    left: -1px;
  }
}
@media (max-width: 767px) {
  .notifies .cntnt {
    font-size: 13px;
    text-align: left;
    padding: 0 !important;
  }
  .notification-box .row.notification .setWidthImg,
  .notification-box .row.notification .setWidth {
    padding: 0 !important;
    text-align: left;
    width: max-content;
  }
  .notification-box .row.notification .row {
    gap: 14px;
    align-items: center;
    padding: 0;
  }
  .notification-box .row.notification.unread .row {
    padding: 0 10px;
  }
  button.d-flex.justify-content-center.align-items-center.text-black.px-3.text-md.br-50.user-icon,
  button#dropdownMenuButton1,
  button.d-flex.justify-content-center.align-items-center.text-black.py-2.pe-3.text-md.br-50.user-icon {
    left: 0 !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  button.d-flex.justify-content-center.align-items-center.text-black.py-2.pe-3.text-md.br-50.user-icon {
    left: 0;
  }
  .dates {
    font-size: 12px;
    text-align: right;
    text-decoration: underline;
  }
}
