thead {
  background: RGBA(237, 248, 246, 1) !important;
}

table thead th {
  color: #000 !important;
  font-weight: 600 !important;
  font-size: 14px !important;
}

table tbody tr td {
  color: #52575d !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

tbody {
  border: none !important;
}

table tbody tr {
  border-bottom: 1px solid #fef2eb;
}

table tbody tr:hover {
  background: RGBA(237, 248, 246, 1) !important;
}

table tbody tr:hover td:first-child {
  border-radius: 10px 0 0 10px !important;
}

table tbody tr:hover td:last-child {
  border-radius: 0px 10px 10px 0px !important;
}

.page span {
  border: 1px solid #b3b8bd;
  color: #b3b8bd;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  cursor: pointer;
  border-radius: 50%;
}

.page.number span {
  color: #ff385c;
}

.page.number.active span {
  color: white;
  background-color: #ff385c;
}

.page span:hover {
  border: 1px solid black;
  color: black;
}

.sent-btn {
  background-color: #ff385c;
  color: white;
  padding: 3px 10px;
  border-radius: 4px;
  text-align: center;
  cursor: no-drop;
}

.send-btn {
  border: 1px solid #ff385c;
  color: #ff385c;
  padding: 3px 10px;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
}

.entryField {
  background-color: #f2f2f2;
}

@media (max-width: 767px) {
  .table-sorting {
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }
  .table-sorting p {
    margin-left: 0 !important;
    margin-bottom: 0 !important;
  }
  .table-sorting .d-flex.me-3 {
    margin-right: 0 !important;
  }
}
