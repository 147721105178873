.column {
  height: 100vh;
}

.title {
  font-weight: 600;
  font-size: 17px;
}

.description {
  font-size: 14px;
  letter-spacing: 1px;
}

.agency .form-check {
  width: 100%;
  float: left;
}

.loggedIn {
  font-size: 13px;
  padding-top: 3px;
}

.tc {
  float: left;
  width: 100%;
}

.tc .form-check {
  width: 10px;
  float: left;
}

.tandc a {
  position: relative;
  top: -15px;
}

.register-dob i {
  color: red !important;
}



/* sign-up-page css start here */

.main-container button.btn {
  font-size: 15px;
  padding: 15px 20px !important;
  border-radius: 4px;
}

.sign-up-logo img {
  width: 164px;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.bg-f8 {
  background: #f8f8f8;
}

/* .bg-ff3 {
  background: #FF385C;
} */

.color-ff3 {
  color: #ff385c;
}

.rounded-30 {
  border-radius: 30px;
}

.sign-btn button {
  background: #ff385c;
  color: #fff;
  width: 150px;
}

.sign-btn button:hover {
  border: 1px solid #ff385c;
}

.login-btn button {
  background: #ebebeb;
  color: #000;
  width: 150px;
}

p {
  margin-bottom: 0 !important;
}

.border-e {
  border: 1px solid #e7e7e7;
}

.rounded-10 {
  border-radius: 10px;
}

.bg-eb {
  background: #ebebeb;
}

.s-form input {
  border-radius: 9px;
}

.color-42 {
  color: #4285f4;
}

.term-privacy-check label {
  color: #4285f4;
}

.or-text p {
  position: relative;
}

.or-text p:after {
  content: '';
  background: #e7e7e7;
  position: absolute;
  width: 70px;
  height: 1px;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.or-text p:before {
  content: '';
  background: #e7e7e7;
  position: absolute;
  width: 70px;
  height: 1px;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.sign-in-info .email input {
  border: 1px solid #ff385c;
}

.email input {
  border: 1px solid #ff385c;
  border-radius: 10px;
}

.pasword-input input {
  border-radius: 10px;
}

/* sign-up-page css end here */

@media (max-width: 767px) {
  .col-12.auth-layot-right {
    margin-left: 0 !important;
  }

  .auth-lyot-rw .column .layoutImg {
    display: none;
  }

  .login-flex {
    flex-flow: column;
  }

  .login-flex > div.mb-3 {
    margin-bottom: 0 !important;
  }

  .auth-layout .column {
    margin: 10px 0 !important;
  }

  .layoutImg {
    width: 100%;
  }

  .form-space {
    padding: 0 !important;
  }
}

@media screen and (max-width: 546px) {
  .column {
    justify-content: center;
    height: unset;
    margin: 30px;
  }
}

@media screen and (min-width: 546px) {
  .auth-layout {
    margin-top: -30px;
  }
}

.layoutImg {
  width: 90vw;
  object-fit: contain;
}
